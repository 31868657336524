.RideCard {
  margin-top: 1rem;
}
.rideDirection {
    margin: 1rem;
    border: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: 1px solid darkgray;
}

.rideDirection .rideDirectionLabel {
    font-size: 0.8rem;
    font-weight: bold;
    font-variant: small-caps;
    color: rgba(123, 5, 250);
}

.meetingPoint {
    margin: 0.9rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}


