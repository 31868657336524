

.CardsContainer {
  height: 80vh;
  overflow: auto;

}
.Period {
  margin-left: 1rem;
}

.NoEvent {
  padding: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledWrrow {
  color: lightgray;
}
