.Logo {
  display: flex;
  width: 90%;
  height: auto;
  vertical-align: middle;
  justify-content: center;
  align-content: center ;
  align-items: center;

}

