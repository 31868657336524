
.navbar {
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 500px;
  flex: 1;
  gap: 14px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.Menu {
  border: 1px solid #7b05fa;
  background-color: #7b05fa;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  min-height: 0.4rem;
}

.Menu a {
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  gap: 0.5rem;
  width: 80%;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}

.ItemMenu {
  display: flex;
  flex: 1;
}

.Ride {
  stroke-width: 0;
  width: 30px;
  height: 30px;
}

.cls-1 {
  fill: white;

}

.icon-team {
  fill: white;
  width: 30px;
  height: 30px;
}

.Reservation {
  fill: white;
  width: 30px;
  height: 30px;
}

.Home,
.TeamIcon,
.Profile {
  width: 30px;
  height: 30px;
}


.TeamIcon {
  stroke-width: 3px;
  width: 30px;
  height: 30px;
}

/* svg {
  width: 30px;
  height: 30px;
} */

.ItemMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  justify-items: center;
  gap: 0.5rem;
}