.RideBooking {
 padding: 1.5rem;
}

.passenger {
    padding: 0.6rem;
    border: 1px solid #7b05fa;
    border-radius: 8px;
}

.passenger.selected {
    background-color: #7b05fa;
    color: white;
    font-weight: bold;
}

.passenger svg.Profile path {
    color: black;
    stroke: black;
}

.passenger.selected svg.Profile path {
    color: white;
    stroke: white;
}