.Notif {
  width: auto;
  height: 30px;
  fill: white;
}

.Notification {
  display: flex;
  flex-direction: row;
  padding-right: 0.8rem;
  padding-left: 0.3rem;
}

.notif-1 {
  fill: white;
}

.notif-2 {
  fill: #e32222;
}

.NotificationPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: white;
  z-index: 2000;
}

.NotificationPanel ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 4rem;
}

.NotificationPanel ul li .NotificationItem {
  color: black;
  padding: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgray;
  font-weight: 400;
}

.NotificationPanel ul li .NotificationItem.unread {
  font-weight: bolder;
  background-color: rgba(123, 5, 250, 0.1);
}

