.ContainerLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh !important;
}

.Loading {
  font-size: 24px;
  gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.CarLoadings {
  animation: moveCar 2s infinite;
  width: 80% !important;

}


.cls-1-yellow {
  fill: #7b05fa;
}

.cls-1-yellow, .cls-2-car, .cls-3-car, .cls-4-car, .cls-5-car, .cls-6-car, .cls-7-car {
  stroke-width: 0px;
}

.cls-3-car {
  fill: #fff;
}

.cls-4-car {
  fill: #feffff;
}

.cls-5-car {
  fill: #7b05fa;
}

.cls-6-car {
  fill: #f03;
}

.cls-7-car {
  fill: #dbdbdb;
}
@keyframes moveCar {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}