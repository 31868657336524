.TeamSelector ul.TeamSelectorList {
  margin-left: auto;
  width: 90%;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.TeamSelector ul.TeamSelectorList li {
  display: flex;
  width: 50%;
  border-radius: 10px;
  justify-content: center;
  border: 1px solid #7b05fa;

}

.TeamSelector ul.TeamSelectorList li a {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  height: 100%;
  color: black;
  text-decoration: none;
  /* border-radius: 10px; */
  text-align: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.TeamSelector ul.TeamSelectorList li:hover {
  background-color: lightgray;
  font-weight: bolder;
}

.TeamSelector h2 {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  color: #7b05fa;
  margin-left: 1rem;
  margin-bottom: 0;
}

.TeamSelectorList {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  min-height: 20vh;
  list-style: none;
}

.TeamSelectorList li {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}

.IconSoccer {
  margin: 1rem;
}

.CenterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.TeamSelector {
  padding-left: '1.5rem';
  padding-right: '1.5rem';
}

.TeamTile {
  padding: 1rem;
  margin: 0.3rem;
  border-radius: 10px;
  border: 1px solid #7b05fa;  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TeamTile .TeamName {
  font-size: 1.3rem;
  font-weight: bold;
}

a.TeamTile,
a.TeamTile:hover,
a.TeamTile:visited,
a.TeamTile:active {
  text-decoration: none;
  color: #7B05FA;
}

a.TeamTile:hover,
a.TeamTile:active {
  text-decoration: none;
  background-color: #7B05FA;
  color: white;
}

.MyTeams {
  padding: 1rem;
  /* padding-top: 1rem; */
}

.TeamName {
  margin-left: 1.5rem;
}