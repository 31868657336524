@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* button {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

h1 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 24px;
  font-weight: 'Semibold';
  color: #7b05fa;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h2 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #7b05fa;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

p {
  margin-left: 1rem;
  margin-right: 1rem;
}

.App {
  max-width: 500px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}





header {
  color: white;
  border-radius: 0px 0px 5px 5px;
  border-style: solid;
  border-color: #7b05fa;
  border-width: 1px;
  padding: 10px;
  text-align: left;
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  min-height: 0.4rem;
  background-color: #7b05fa;
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  max-width: 500px;
  flex: 1;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

footer {
  font-size: 12px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 500px;
  flex: 1;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.Quota {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: left;
  border: 1px solid #7b05fa;
  border-radius: 20px;
  background-color: #ffffff;
  color: #000000;
  padding: 0.5rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  font-size: 12px;
  margin-left: auto;
  min-width: 3.2rem;
}

.QuotaCar {
  width: 22px;
  height: auto;
  margin-right: 0.5rem;
}

/* .MuiFormControl-root .MuiTextField-root label { */
label {
  z-index: 0 !important;
  font-size: 14px !important;
}

.spacing {
  min-height: 4rem;
}