.CardsAvailableCars {
  margin-bottom: 0.5rem;

}

/* .MonthSelect,
.chevronLeft,
.chevronRight,
.Month {
  display: inline;
}


.MonthSelect {
gap: 16px;
justify-content: center;
} */

.chevronLeft,
.chevronRight {
  width: 20px;
  height: 20px;

}

.AvailableCarsCard {
  display: flex;
  border: #7b05fa 1px solid;
  border-radius: 15 0 15 0;
  border-radius: 15px;
  height: 15rem;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
}

.EventDateCard {
  background: #7b05fa;
  border-radius: 15px 0px 0px 15px;
  width: 20%;
  height: 100%;
  position: relative;
  display: flex;
  /* display: flex;
flex-direction: column; */
  align-items: center;
  /* justify-content: flex-start ; */
  align-self: stretch;
  flex-shrink: 0;

}

.EventDate {
  color: #ffffff;
  text-align: center;
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  /* position: absolute;
top: 20%; */
  align-items: center;
  height: 30%;
  margin: auto;
  /* vertical-align: middle; */
  /* width: 66px;
height: 100%; 
/* justify-content: center; */
  /* padding-top: 100%; */

}

.DayDate {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  align-self: stretch;
  padding: 5%;
  /* height: 28.95px; */
}

.NumberDayDate,
.MonthDate {
  font-family: 'Poppins-SemiBold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  align-self: stretch;
  padding: 5%;
}

.HourEvent {
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  align-self: stretch;
  padding: 5%;
}

.AvailableCars {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 0.3rem;
}


.LocationEvent {
  display: flex;
  flex-direction: column;
  /* margin-left: 0.5rem; */

}

.TitleTrip {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.IconLocation,
.LocationIcon,
.Location {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.Location {
  justify-content: center;
  align-items: center;
}

.IconLocation {
  font-family: 'Poppins-Medium', sans-serif;

}

.FillIconLocation {
  fill: #ffffff;
}

.LocationIcon {
  width: 12px;
  height: auto;
  margin-right: 0.3rem;
}

.AddressLocation {
  font-family: 'Poppins-Regular', sans-serif;
  margin-left: 1.5rem;
  padding-bottom: 1rem;
}

.RideInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: auto;

}

.CarInfo {
  padding: 0.3rem;
  padding-top: 0.5rem;

}

.SeatsInfo {
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  font-weight: 500;

}

.SeatsInfo.Available {
  color: green;
}

.SeatsInfo.Full {
  color: red;
  font-weight: bold;
}


svg.CarInfo {
  width: 20px;
  height: auto;
}

.SeatIcon {
  height: auto;
  /* margin-right: 0rem; */
}

.CarsMore {
  text-align: center;
  margin-top: 0.5rem;
}

/* .ButtonsGroup,
.button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */


/* .EventCardButtons {
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  /* width: 100%; */
/* } */

.NoRides {
  text-align: center;
  font-weight: bold;
  padding: 1rem;
}